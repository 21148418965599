import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek'; // For Monday as the first day of the week

import {
  ArrowLeftIcon, ArrowRightIcon
} from "@heroicons/react/24/outline";

import { PALETTE } from "../../lib/constants";
import { SecondaryButtonM } from "../../lib/Buttons";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Extend dayjs with necessary plugins
dayjs.extend(localizedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

const SEIZURE_TYPES_COLORS = {
  "Fokalt utan medvetandepåverkan": PALETTE.type01,
  "Fokalt med medvetandepåverkan": PALETTE.type02,
  "Fokalt till generaliserat": PALETTE.type03,
  "Tonisk-kloniskt": PALETTE.type04,
  "Absens": PALETTE.type05,
  "Myokloniskt": PALETTE.type06,
  "Kloniskt": PALETTE.type07,
  "Toniskt": PALETTE.type08,
  "Atoniskt": PALETTE.type09,
  "Okänt": PALETTE.type10
}

const useSeizuresData = (timeCount, viewType) => {
  const seizures = useSelector((state) => state.user.seizures);

  return useMemo(() => {
    let startPeriod;
    let periodLength;

    if (viewType === "weekly") {
      startPeriod = dayjs().isoWeekday(1).subtract(timeCount, "week");
      periodLength = 7;
    } else if (viewType === "monthly") {
      startPeriod = dayjs().startOf("month").subtract(timeCount, "month");
      periodLength = dayjs(startPeriod).daysInMonth();
    } else { // Annual view
      startPeriod = dayjs().startOf("year").subtract(timeCount, "year");
      periodLength = 12; // 12 months in a year
    }

    let seizureNumbers = [];
    let datasets = Object.keys(SEIZURE_TYPES_COLORS).reduce((acc, type) => {
      acc[type] = Array(periodLength).fill(0); // Initialize period with 0 seizures
      return acc;
    }, {});

    for (let i = 0; i < periodLength; i++) {
      const periodDate = viewType === "annual" ? startPeriod.add(i, 'month') : startPeriod.add(i, 'day');
      seizures.forEach(({ date: seizureDate, type }) => {
        const seizureDay = dayjs(seizureDate);
        if ((viewType === "annual" && seizureDay.month() === i && seizureDay.year() === startPeriod.year()) ||
            (viewType !== "annual" && periodDate.format('L') === seizureDay.format('L'))) {
          if (datasets[type] !== undefined) {
            datasets[type][i]++;
          }
        }
      });
      if (viewType === "annual") {
        seizureNumbers.push(seizures.filter(({ date }) => dayjs(date).month() === i && dayjs(date).year() === startPeriod.year()).length);
      } else {
        const formattedDate = periodDate.format('L');
        seizureNumbers.push(seizures.filter(({ date }) => dayjs(date).format('L') === formattedDate).length);
      }
    }

    return {
      seizureNumbers,
      periodDays: viewType === "annual" 
        ? Array.from({ length: 12 }, (_, i) => dayjs().month(i).format('MMM'))
        : Array.from({ length: periodLength }, (_, i) => startPeriod.add(i, 'day').format(viewType === 'weekly' ? 'ddd' : 'D')),
      datasets: Object.entries(datasets).map(([type, data]) => ({
        label: type,
        data,
        backgroundColor: SEIZURE_TYPES_COLORS[type],
        borderColor: SEIZURE_TYPES_COLORS[type],
        borderWidth: 1,
        stack: "stack0"
      }))
    };
  }, [seizures, timeCount, viewType]);
};

const Diagram = () => {
  const [timeCount, setTimeCount] = useState(0);
  const [viewType, setViewType] = useState('weekly'); // 'weekly', 'monthly' or 'annual'
  const { seizureNumbers, periodDays, datasets } = useSeizuresData(timeCount, viewType);

  const handleTimeCount = (step) => setTimeCount((current) => current + step);

  const chartData = {
    labels: periodDays,
    datasets
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max: Math.max(5, ...seizureNumbers) + 1,
        ticks: {
          stepSize: 1,
        },
        stacked: true
      },
    },
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
      },
    },
  };

  return (
    <>
      <div className="flex items-center justify-evenly mb-4">
        <button onClick={() => setViewType('weekly')} className={`rounded-md w-24 px-4 py-2 shadow-md ring-1 ring-inset ring-slate-300 ${viewType === 'weekly' ? 'bg-slate-200' : 'bg-slate-050'}`}>Vecka</button>
        <button onClick={() => setViewType('monthly')} className={`rounded-md w-24 px-4 py-2 shadow-md ring-1 ring-inset ring-slate-300 ${viewType === 'monthly' ? 'bg-slate-200' : 'bg-slate-050'}`}>Månad</button>
        <button onClick={() => setViewType('annual')} className={`rounded-md w-24 px-4 py-2 shadow-md ring-1 ring-inset ring-slate-300 ${viewType === 'annual' ? 'bg-slate-200' : 'bg-slate-050'}`}>År</button>
      </div>
      <div className="flex flex-row justify-between items-center mb-4">
        <SecondaryButtonM buttonText={<ArrowLeftIcon className="h-6 w-6"/>} onClick={() => handleTimeCount(1)} />
        <div className="text-center">
          <>{viewType === 'annual' ? dayjs().startOf('year').subtract(timeCount, 'year').format('YYYY') : (viewType === 'weekly' ? dayjs().isoWeekday(1).subtract(timeCount, 'week').format('L') : dayjs().startOf('month').subtract(timeCount, 'month').format('MMMM YYYY'))}</>
          <>{viewType === 'weekly' ? ` - ${dayjs().isoWeekday(1).subtract(timeCount, 'week').add(6, 'day').format('L')}` : ""}</>
          <p>{seizureNumbers.reduce((accumulator, currentValue) => accumulator + currentValue, 0)} anfall</p>
        </div>
        <SecondaryButtonM buttonText={<ArrowRightIcon className="h-6 w-6"/>} onClick={() => handleTimeCount(-1)} disabled={timeCount === 0} />
      </div>
      <div className="h-72 p-2.5">
        <Bar data={chartData} options={options} />
      </div>
    </>
  )
};

export default Diagram;