import React from "react";
import { Provider } from "react-redux";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { user } from "./reducers/user";
import { ui } from "./reducers/ui";

import Header from "./components/header/Header";
import LoaderPage from "./components/LoaderPage";
import StartPage from "./components/StartPage";
import Dashboard from "./components/dashboard/Dashboard";
import Seizures from "./components/seizures/Seizures";
import SeizureRegistration from "./components/seizures/SeizureRegistration";
import Contacts from "./components/contacts/Contacts";
import Wallpaper from "./components/wallpaper/Wallpaper";
import Profile from "./components/profile/Profile";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Footer from "./components/Footer";
import WallpaperFinal from "./components/wallpaper/WallpaperFinal";

import { USERS_URL } from "./variables/apiUrl";
import { LOGIN_URL } from "./variables/apiUrl";
import { USERDATA_URL } from "./variables/apiUrl";
import { SEIZURES_URL } from "./variables/apiUrl";
import { CONTACTS_URL } from "./variables/apiUrl";
import Login from "./components/authentication/Login";

const reducer = combineReducers({
  user: user.reducer,
  ui: ui.reducer,
});
const store = configureStore({ reducer });

const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <Header USERDATA_URL={USERDATA_URL}/>
        <Switch>
          <Route path="/" exact>
            <StartPage
              LOGIN_URL={LOGIN_URL}
              USERDATA_URL={USERDATA_URL}
              USERS_URL={USERS_URL}
            />
            <LoaderPage />
          </Route>
          <Route path="/inloggning">
            <Login
              LOGIN_URL={LOGIN_URL}
              USERS_URL={USERS_URL}
            />
            {/* <LoaderPage /> */}
          </Route>
          <Route path="/anfallsdata">
            <Seizures SEIZURES_URL={SEIZURES_URL} USERDATA_URL={USERDATA_URL} />
            <LoaderPage />
          </Route>
          <Route path="/anfallsregistrering">
            <SeizureRegistration SEIZURES_URL={SEIZURES_URL} USERDATA_URL={USERDATA_URL} />
            <LoaderPage />
          </Route>
          <Route path="/statistik">
            <Dashboard USERDATA_URL={USERDATA_URL} />
            <LoaderPage />
          </Route>
          <Route path="/kontakter">
            <Contacts CONTACTS_URL={CONTACTS_URL} USERDATA_URL={USERDATA_URL} />
            <LoaderPage />
          </Route>
          <Route path="/bakgrundsbild" exact>
            <Wallpaper USERDATA_URL={USERDATA_URL} />
          </Route>
          <Route path="/bakgrundsbild/preview">
            <WallpaperFinal USERDATA_URL={USERDATA_URL} />
          </Route>
          <Route path="/profil">
            <Profile USERDATA_URL={USERDATA_URL} />
            <LoaderPage />
          </Route>
          <Route path="/integritetspolicy">
            <PrivacyPolicy />
          </Route>
          <Route path="/404">
            <StartPage LOGIN_URL={LOGIN_URL} USERDATA_URL={USERDATA_URL} />
          </Route>
          <Route render={() => <Redirect to="/404" />} />
        </Switch>
        <Footer />
      </Provider>
    </BrowserRouter>
  );
}

export default App;