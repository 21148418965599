import React from "react";
import { useSelector } from "react-redux";

import { StyledFooter, StyledFooterLink } from "../lib/Styling";
import version from "../variables/version.json";

const Footer = () => {
  const isFullScreen = useSelector((store) => store.ui.isFullScreen);
  return (
    <>
      {!isFullScreen &&
        <StyledFooter>
          <StyledFooterLink href="mailto:support@epapp.se">Kontakta oss</StyledFooterLink>
          <br />
          Utvecklat av Techstorming, en del av Mindstorming AB
          <br />
          v.{version.number}
        </StyledFooter>
      }
    </>
  )
};

export default Footer;