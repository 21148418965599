import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";

import { updateContact } from "../../reducers/reusable";
import { user } from "../../reducers/user";
import contactList from "../../assets/contact-types.json";

import { StyledCardSelect, StyledCard, StyledForm, StyledCardInput, StyledCardLabel, StyledGrid } from "../../lib/Styling";
import { PrimaryButtonL, SecondaryButtonL } from "../../lib/Buttons";

const ContactForm = ({ CONTACTS_URL, contact, toggleEditMode }) => {
  const dispatch = useDispatch()
  // const storedContactTypes = useSelector((store) => store.user.contactTypes);
  const storedContactTypes = contactList;
  const localToken = localStorage.getItem("localToken");
  const localId = localStorage.getItem("localId");
  const permissionLevel = useSelector((store) => store.user.profile.permissionLevel);
  const _id = contact._id;

  const [type, setType] = useState(contact.type);
  const [firstName, setFirstName] = useState(contact.firstName);
  const [surname, setSurname] = useState(contact.surname);
  const [phoneNumber, setPhoneNumber] = useState(contact.phoneNumber);
  const [category, setCategory] = useState(contact.category);
  const [newType, setNewType] = useState("");

  const updatedContact = { _id, type, firstName, surname, phoneNumber, category };

  const handleEdit = (event) => {
    event.preventDefault();
    swal({
      title: "Är du säker på att du vill spara dina ändringar?",
      text: "Det går inte att ångra ändringen när den väl är sparad.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willSave) => {
        if (willSave) {
          if (permissionLevel === "demo") {

            dispatch(user.actions.updateContact(updatedContact));
          } else {
            dispatch(updateContact(CONTACTS_URL, localToken, localId, updatedContact));
          }
          toggleEditMode();
        }
      })
  };

  return (
    <StyledForm onSubmit={handleEdit}>
      <StyledCard>
        <StyledGrid>
          <StyledCardLabel htmlFor="type">
            Typ:
        </StyledCardLabel>
          <StyledCardSelect
            id="type"
            required
            defaultValue={type}
            onChange={event => {
              setType(event.target.value);
              setNewType(event.target.value);
            }
            } >
            <option value={type} disabled>{type}</option>
            {storedContactTypes.map(type => {
              return (<option key={type.name} value={type.name}>{type.name}</option>)
            })}
          </StyledCardSelect>
          <StyledCardLabel htmlFor="firstname">
            Förnamn:
        </StyledCardLabel>
          <StyledCardInput
            required
            id="firstname"
            minLength="2"
            type="text"
            value={firstName}
            onChange={event => setFirstName(event.target.value)} >
          </StyledCardInput>
          <StyledCardLabel htmlFor="surname">
            Efternamn:
        </StyledCardLabel>
          <StyledCardInput
            id="surname"
            minLength="2"
            type="text"
            value={surname}
            onChange={event => setSurname(event.target.value)} >
          </StyledCardInput>
          <StyledCardLabel htmlFor="phonenumber">
            Telefonnr:
        </StyledCardLabel>
          <StyledCardInput
            required
            id="phonenumber"
            minLength="2"
            type="tel"
            value={phoneNumber}
            onChange={event => setPhoneNumber(event.target.value)} >
          </StyledCardInput>
          {!newType && (
            <>
              <StyledCardLabel htmlFor="noChangeRelation">
                Relation:
              </StyledCardLabel>
              <StyledCardSelect
                id="noChangeRelation"
                required
                defaultValue={category}
                onChange={event => setCategory(event.target.value)} >
                <option value={category} disabled>{category}</option>
                {storedContactTypes.find(item => item.name === type).categories.map(category => {
                  return (<option key={category} value={category}>{category}</option>)
                })}
              </StyledCardSelect>
            </>
          )}
          {newType && (
            <>
              <StyledCardLabel htmlFor="changeRelation">
                Relation:
              </StyledCardLabel>
              <StyledCardSelect
                id="changeRelation"
                required
                defaultValue=""
                onChange={event => setCategory(event.target.value)} >
                <option value="" disabled>Välj relation</option>
                {storedContactTypes.find(item => item.name === type).categories.map(category => {
                  return (<option key={category} value={category}>{category}</option>)
                })}
              </StyledCardSelect>
            </>
          )}
        </StyledGrid>
        <SecondaryButtonL normal buttonText="Avbryt" onClick={toggleEditMode} />
        <PrimaryButtonL buttonText="Spara" type="submit" />
      </StyledCard>
    </StyledForm>
  )
};

export default ContactForm;