import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import { fetchUserData } from "../../reducers/reusable";

import Seizure from "./Seizure";
import SeizureExport from "./SeizureExport";
import Accordion from "../../lib/Accordion";
import seizureList from "../../assets/seizure-types.json";

import { StyledTitle, StyledSection, StyledEPText, StyledSubSection } from "../../lib/Styling";
import NavigationButtonWithProps from "../buttons/NavigationButtonWithProps";

const Seizures = ({ SEIZURES_URL, USERDATA_URL }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const localToken = localStorage.getItem("localToken");
  const localId = localStorage.getItem("localId");
  const storedId = useSelector((store) => store.user.profile.userId);
  const permissionLevel = useSelector((store) => store.user.profile.permissionLevel);
  const seizures = useSelector((store) => store.user.seizures);
  const isLoading = useSelector((store) => store.ui.isLoading);

  const faqArray = [
    {
      type: "list-none",
      question: "Varför kan jag inte välja datum och tid?",
      answers: [
        <>
          I vissa webbläsare (t.ex. Safari på MacOS) visas ingen kalender när du ska ange datum och tid. Du behöver skriva dem för hand.
        </>,
        <>
          Om det är ditt fall, lämna datumet tomt när du registrerar ett nytt anfall. Det blir dagens datum och tid per automatik och det är enklare att redigera efteråt.
        </>
      ]
    },
    // {
    //   type: "list-none",
    //   question: "Vad är epilepsi?",
    //   answers: [
    //     <>
    //       Epilepsi är en sjukdom i hjärnan som orsakar olika typer av anfall.
    //     </>,
    //     <>
    //       Hur anfallen blir beror på vilken del av hjärnan som är påverkad. Därför indelas epileptiska anfall i två huvudtyper: <strong>generaliserade</strong> anfall, då stora delar av hjärnan är inblandad från anfallets start, och <strong>fokala</strong> anfall, som startar i en begränsad del av hjärnan.
    //     </>,
    //     <>
    //       Anfallen kan vara mycket olika för olika personer. Hos de flesta är anfallen liknande varje gång, men en del kan få flera olika typer av anfall.
    //     </>
    //   ]
    // },
    {
      type: "list-none",
      question: "Vad är det för skillnad mellan de olika anfallstyperna?",
      answers: [
        <>
          Här kommer en kort beskrivning av de olika anfallstyperna, hämtade från 1177.se. Är du osäker på vilken typ som passar ditt anfall, välj "Okänt".
        </>,
        seizureList.map((seizure, index) =>
          <p key={index} className="mb-2 last:mb-0" >
            <strong>{seizure.name}</strong><br />
            {seizure.description}
          </p>
        )
      ]
    },
  ]

  useEffect(() => {
    if (!permissionLevel && !localToken) {
      history.push("/inloggning")
    }
    if (!storedId && localId) {
      dispatch(fetchUserData(USERDATA_URL, localToken, localId));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!isLoading &&
        <StyledSection center>
          <StyledTitle>Anfallsdata</StyledTitle>
          <StyledSubSection>
            <StyledEPText>På den här sidan kan du registrera dina anfall.</StyledEPText>
            {faqArray.map((faq, index) => {
          return (<Accordion key={index} faq={faq}></Accordion>)
        })}
          </StyledSubSection>
          <NavigationButtonWithProps label="Registrera nytt anfall" route="anfallsregistrering" extraProps={{ seizureToEdit: {} }}/>
          <SeizureExport />
          {seizures.map(seizure => {
            return (<Seizure key={seizure._id} seizure={seizure} SEIZURES_URL={SEIZURES_URL}></Seizure>)
          })}
        </StyledSection>
      }
    </>
  )
};

export default Seizures;