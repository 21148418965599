import React from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from '@heroicons/react/20/solid';

const Accordion = (props) => {
  return (
    <Disclosure as="div" className="mb-4 last:mb-0">
      {({ open }) => (
        <>
          <Disclosure.Button
            className={"flex w-full justify-between rounded-lg bg-slate-100 px-4 py-2 text-left font-medium hover:bg-slate-200 focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 " + (props.large ? "text-m text-slate-800" : "text-sm text-slate-700")}
          >
            <span>{props.faq.question}</span>
            <ChevronDownIcon
              className={`${open ? 'rotate-180 transform' : ''
                } h-5 w-5 ` + (props.large ? "text-m text-slate-800" : "text-sm text-slate-700")}
            />
          </Disclosure.Button>
          <Disclosure.Panel
            className={`${props.faq.type} px-4 pt-4 pb-2 ` + (props.large ? "text-m text-slate-800" : "text-sm text-slate-700")}
          >
            {props.faq.answers.map((answer, index) =>
              <li className={"pb-2"} key={index}>{answer}</li>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
};

export default Accordion;