import React from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import locale from "dayjs/locale/sv";

import { StyledCard, StyledGrid, StyledCardText } from "../../lib/Styling";

const ProfileCard = () => {
  const { email, firstName, surname, birthDate } = useSelector((store) => store.user.profile);

  return (
    <StyledCard>
      <StyledGrid>
        <StyledCardText left>E-post:</StyledCardText>
        <StyledCardText>{email}</StyledCardText>
        <StyledCardText left>Förnamn:</StyledCardText>
        <StyledCardText>{firstName}</StyledCardText>
        <StyledCardText left>Efternamn:</StyledCardText>
        <StyledCardText>{surname}</StyledCardText>
        <StyledCardText left>Födelsedatum:</StyledCardText>
        <StyledCardText>{!birthDate ? "" : dayjs(birthDate).locale({ ...locale }).format("DD MMM YYYY")}</StyledCardText>
      </StyledGrid>
    </StyledCard>
  )
};

export default ProfileCard;