import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import { fetchUserData } from "../../reducers/reusable";

import Diagram from "./Diagram";

import { StyledTitle, StyledSection } from "../../lib/Styling";
// import { StyledTitle, StyledSection, StyledEPText } from "../../lib/Styling";

const Dashboard = ({ USERDATA_URL }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const localToken = localStorage.getItem("localToken");
  const localId = localStorage.getItem("localId");
  const storedId = useSelector((store) => store.user.profile.userId);
  const permissionLevel = useSelector((store) => store.user.profile.permissionLevel);
  const isLoading = useSelector((store) => store.ui.isLoading);

  useEffect(() => {
    if (!permissionLevel && !localToken) {
      history.push("/inloggning")
    }
    if (!storedId && localId) {
      dispatch(fetchUserData(USERDATA_URL, localToken, localId));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!isLoading &&
        <StyledSection>
          <StyledTitle>Statistik</StyledTitle>
          {/* <StyledEPText>Nedan kan du se en överblick över din anfallsstatistik.</StyledEPText> */}
          <Diagram />
        </StyledSection>
      }
    </>
  );
};

export default Dashboard;