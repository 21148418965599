import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";
import dayjs from "dayjs";
import locale from "dayjs/locale/sv";

import { updateProfile } from "../../reducers/reusable";
import { user } from "../../reducers/user";

import { StyledForm, StyledCardInput, StyledCardLabel, StyledCard, StyledGrid, StyledCardText } from "../../lib/Styling";
import { PrimaryButtonL, SecondaryButtonL } from "../../lib/Buttons";

const ProfileForm = ({ USERDATA_URL, toggleEditMode }) => {
  const dispatch = useDispatch();
  const localToken = localStorage.getItem("localToken");
  const localId = localStorage.getItem("localId");
  const permissionLevel = useSelector((store) => store.user.profile.permissionLevel);

  const [email, setEmail] = useState(useSelector((store) => store.user.profile.email));
  const [firstName, setFirstName] = useState(useSelector((store) => store.user.profile.firstName));
  const [surname, setSurname] = useState(useSelector((store) => store.user.profile.surname));
  const [birthDate, setBirthDate] = useState(dayjs(useSelector((store) => store.user.profile.birthDate)).locale({ ...locale }).format("YYYY-MM-DD"));

  const updatedProfile = { email, firstName, surname, birthDate };
  const localProfile = { firstName, surname, birthDate };

  const handleEdit = (event) => {
    event.preventDefault();
    swal({
      title: "Är du säker på att du vill uppdatera din profil?",
      text: "Det går inte att ångra ändringen när den väl är sparad.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willSave) => {
        if (willSave) {
          if (permissionLevel === "demo") {
            dispatch(user.actions.updateLocalProfile(localProfile));
          } else {
            dispatch(updateProfile(USERDATA_URL, localToken, localId, updatedProfile));
          }
          toggleEditMode();
        }
      })
  };

  return (
    <StyledForm onSubmit={handleEdit}>
      <StyledCard>
        <StyledGrid>
        {permissionLevel === "demo"
        ? 
          <>
            <StyledCardText left>E-post:</StyledCardText>
            <StyledCardText>{email}</StyledCardText>
          </>
        :
          <>
            <StyledCardLabel htmlFor="email">
              E-post:
            </StyledCardLabel>
            <StyledCardInput
              id="email"
              required
              minLength="5"
              type="email"
              value={email}
              name="email"
              onChange={event => setEmail(event.target.value)} >
            </StyledCardInput>
          </>
        }
          <StyledCardLabel htmlFor="firstname">
            Förnamn:
          </StyledCardLabel>
          <StyledCardInput
            id="firstname"
            required
            minLength="2"
            type="text"
            value={firstName}
            onChange={event => setFirstName(event.target.value)} >
          </StyledCardInput>
          <StyledCardLabel htmlFor="surname">
            Efternamn:
          </StyledCardLabel>
          <StyledCardInput
            id="surname"
            required
            minLength="2"
            type="text"
            value={surname}
            onChange={event => setSurname(event.target.value)} >
          </StyledCardInput>
          <StyledCardLabel htmlFor="birthdate">
            Födelsedatum:
          </StyledCardLabel>
          <StyledCardInput
            required
            id="birthdate"
            type="date"
            placeholder="yyyy-mm-dd"
            pattern="\d{4}-\d{2}-\d{2}"
            value={birthDate}
            onChange={event => setBirthDate(event.target.value)} >
          </StyledCardInput>
        </StyledGrid>
        <SecondaryButtonL normal buttonText="Avbryt" onClick={toggleEditMode} />
        <PrimaryButtonL buttonText="Spara" type="submit" />
      </StyledCard>
    </StyledForm>
  )
};

export default ProfileForm;