import React from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import { PrimaryButtonXL } from "../../lib/Buttons";

const SeizureExport = () => {
    const seizures = useSelector((store) => store.user.seizures);

    const selectProps = (selectedProps) => {
        const { date, duration, type, trigger, comment } = selectedProps;
        return { date, duration, type, trigger, comment };
    };

    const seizuresWithSelectedProps = seizures.map(selectProps);

    const splitDate = (seizure) => {
        const day = seizure.date.substring(0, 10);
        const time = seizure.date.substring(11, 16);
        delete seizure.date;
        seizure = {day: day, time: time, ...seizure}
        return seizure;
      };
    
    const seizuresWithSplit = seizuresWithSelectedProps.map(splitDate);
    
    const seizureValuesArrays = seizuresWithSplit.map(seizure => Object.values(seizure));
    const headers = ["datum", "tid", "längd i sekunder", "typ", "utlösare", "kommentar"];
    seizureValuesArrays.unshift(headers);

    const csvContent = "\uFEFF" + seizureValuesArrays.map(row => row.join(",")).join("\n");
    
    const csvEncodedContent = "data:text/csv;charset=utf-8," + csvContent;

    const encodedUri = encodeURI(csvEncodedContent);

    return (
        <a
            href={encodedUri}
            download={"Anfallsdata_" + dayjs().format("YYYYMMDD-HHmmss-SSS") + ".csv"}
            target="_blank"
            rel="noreferrer"
        >
            <PrimaryButtonXL buttonText="Ladda ner anfallsdata"/>
        </a>
    )
};

export default SeizureExport;