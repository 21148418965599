import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import { fetchUserData, useToggle } from "../../reducers/reusable";

import Contact from "./Contact";
import ContactRegistration from "./ContactRegistration";

import { StyledTitle, StyledSection, StyledEPText, StyledSubSection, StyledEPLink } from "../../lib/Styling";
import { PrimaryButtonXL } from "../../lib/Buttons";

const Contacts = ({ CONTACTS_URL, USERDATA_URL }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [creationMode, toggleCreationMode] = useToggle();
  const localToken = localStorage.getItem("localToken");
  const localId = localStorage.getItem("localId");
  const storedId = useSelector((store) => store.user.profile.userId);
  const permissionLevel = useSelector((store) => store.user.profile.permissionLevel);
  const contacts = useSelector((store) => store.user.contacts);
  const isLoading = useSelector((store) => store.ui.isLoading)

  useEffect(() => {
    if (!permissionLevel && !localToken) {
      history.push("/inloggning")
    }
    if (!storedId && localId) {
      dispatch(fetchUserData(USERDATA_URL, localToken, localId));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!isLoading &&
        <StyledSection center>
          <StyledTitle>Kontakter</StyledTitle>
          <StyledSubSection>
            <StyledEPText>På den här sidan kan du registrera dina nöd- och vårdkontakter.</StyledEPText>
            <StyledEPText>De 2 senaste nödkontakterna du registrerat kommer att visas på bakgrundsbilden du kan skapa på sidan <StyledEPLink href="../bakgrundsbild">Bakgrund</StyledEPLink>.</StyledEPText>
          </StyledSubSection>
          {!creationMode && <PrimaryButtonXL buttonText="Lägg till ny kontakt" onClick={toggleCreationMode} />}
          {creationMode && <ContactRegistration toggleCreationMode={toggleCreationMode} CONTACTS_URL={CONTACTS_URL} />}
          {contacts.map(contact => {
            return (<Contact key={contact._id} contact={contact} CONTACTS_URL={CONTACTS_URL} ></Contact>)
          })}
        </StyledSection>
      }
    </>
  )
};

export default Contacts;