import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import { fetchUserData } from "../../reducers/reusable";

import NavigationButton from "../buttons/NavigationButton";
import Accordion from "../../lib/Accordion";

import { StyledSection, StyledTitle, StyledEPText, StyledSubSection, StyledEPLink } from "../../lib/Styling";

const Wallpaper = ({ USERDATA_URL }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const localToken = localStorage.getItem("localToken");
  const localId = localStorage.getItem("localId");
  const storedId = useSelector((store) => store.user.profile.userId);
  const permissionLevel = useSelector((store) => store.user.profile.permissionLevel);

  const faqArray = [
    {
      type: "list-decimal",
      question: "Hur gör jag?",
      answers: [
        <>
          Klicka på Förhandsgranska för att få fram din bakgrundsbild.
        </>,
        <>
          Ta en skärmdump av bilden och spara den på din mobil.
        </>,
        <>
          Gå till din mobils inställningar och välj bilden som bakgrundsbild.
        </>
      ]
    },
    {
      type: "list-none",
      question: "Varför saknas information på min bakgrundsbild?",
      answers: [
        <>
          Dina förnamn och namn är hämtade från din profil. Du uppdaterar din profil under <StyledEPLink href="../profil">Profil</StyledEPLink>.
        </>,
        <>
          Det är dina 2 senast registrerade nödkontakter som visas som kontakter på bakgrundsbilden. Du uppdaterar dina nödkontakter under <StyledEPLink href="../kontakter">Kontakter</StyledEPLink>.
        </>
      ]
    }
  ]

  useEffect(() => {
    if (!permissionLevel && !localToken) {
      history.push("/inloggning")
    }
    if (!storedId && localId) {
      dispatch(fetchUserData(USERDATA_URL, localToken, localId));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <StyledSection>
      <StyledTitle>Bakgrundsbildsgenerator</StyledTitle>
      <StyledSubSection>
        <StyledEPText>I EP App kan du skapa en bakgrundsbild till din mobiltelefon. Bilden talar om att du har epilepsi och vem man ska kontakta vid anfall.</StyledEPText>
        <StyledEPText>När du är klar kommer du tillbaka till applikationen genom att svajpa tillbaka till den här sidan.</StyledEPText>
        {faqArray.map((faq, index) => {
          return (<Accordion key={index} faq={faq}></Accordion>)
        })}
      </StyledSubSection>
      <StyledSection row>
        <NavigationButton accent route="bakgrundsbild/preview" label="Förhandsgranska" />
      </StyledSection>
    </StyledSection>
  )
};

export default Wallpaper;
