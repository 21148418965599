import React from "react";

import Accordion from "../lib/Accordion";

import { StyledSection, StyledEPText, StyledTitle, StyledSubSection, StyledEPLink } from "../lib/Styling";

const PrivacyPolicy = () => {

  const faqArray = [
    {
      type: "list-none",
      question: "Hur behandlar vi dina personuppgifter?",
      answers: [
        <>
          Vi sparar och behandlar dina personuppgifter, såsom namn, e-postadress, födelsedatum och hälsodata. Syftet med behandlingen är att kunna erbjuda en säker inloggning, statisk och en bakgrundsbild till din mobil.
        </>,
        <>
          Vi har fått dina uppgifter genom registrering i applikationen, av dig eller av de anhöriga du har anförtrott med dina inloggningsuppgifter. Vi tillämpar vid var tid gällande integritetslagstiftning vid all behandling av personuppgifter.
        </>,
        <>
          Den rättsliga grunden för att behandla dina personuppgifter är ditt samtycke. Du har när som helst rätt att återkalla ditt samtycke till behandlingen genom att radera ditt konto. Observera att ett återkallande inte påverkar lagligheten av behandlingen innan samtycket återkallades.
        </>,
        <>
          Dina uppgifter kommer att sparas tills du raderar ditt konto eller högst 60 månader efter din senaste inloggning för att säkra att du inte blir av med din registrerade information.
        </>
      ]
    },
    {
      type: "list-none",
      question: "Vilka delar vi dina personuppgifter med?",
      answers: [
        <>
          De personuppgifter vi behandlar om dig delas inte med andra mottagare.
        </>,
        <>
          I undantagsfall, om det är nödvändigt för att uppfylla våra rättsliga skyldigheter eller för att skydda Ep App eller tredje part mot brott, kommer vi att lämna information till myndigheter.
        </>
      ]
    },
    {
      type: "list-none",
      question: "Var behandlas dina uppgifter?",
      answers: [
        <>
          Din information behandlas i Sverige och lagras på servrar inom EU/EES.
        </>
      ]
    },
    {
      type: "list-none",
      question: "Vilka rättigheter har du?",
      answers: [
        <>
          Personuppgiftsansvarig är Techstorming, en del av Mindstorming AB, org. nr 559120-8953, Frisells Gata 53, 194 69 Upplands Väsby, Sverige.
        </>,
        <>
          Du har rätt att kontakta oss om du vill ha ut information om de uppgifter vi har om dig, för att begära rättelse, överföring eller för att begära att vi begränsar behandlingen, för att göra invändningar eller begära radering av dina uppgifter.
        </>,
        <>
          Detta gör du enklast genom att kontakta oss på <StyledEPLink href="mailto:support@epapp.se">support@epapp.se</StyledEPLink>.
        </>
      ]
    },
    {
      type: "list-none",
      question: "Har du frågor?",
      answers: [
        <>
          Du når vårt dataskyddsombud Sandrine Elander på <StyledEPLink href="mailto:dso@epapp.se">dso@epapp.se</StyledEPLink>. Om du har klagomål på vår behandling av dina personuppgifter har du rätt att inge klagomål till Integritetsskyddsmyndigheten (IMY).
        </>
      ]
    },
  ];

  return (
    <>
      <StyledSection>
        <StyledTitle>Integritetspolicy</StyledTitle>
        <StyledSubSection>
          <StyledEPText>Ep App är en applikation som hjälper dig hantera dina anfall och identifiera eventuella utlösare. Datan sparas säkert i molnet, så du alltid har tillgång till den, oavsett var du är. Du kan logga dina anfall från din smartphone, surfplatta eller dator.</StyledEPText>
          <StyledEPText>Observera att Ep App inte är en medicinteknisk produkt och inte ska användas som ersättning för en professionell medicinsk bedömning. Den är inte utformad eller avsedd att användas till diagnosticering av epilepsi eller för att bota, lindra, behandla eller förebygga epilepsi. Kontakta din läkare innan du fattar några hälsorelaterade beslut.</StyledEPText>
          <StyledEPText>Nedan kan du läsa om hur vi behandlar dina uppgifter.</StyledEPText>
          {faqArray.map((faq, index) => {
          return (<Accordion large key={index} faq={faq}></Accordion>)
        })}
        </StyledSubSection>
      </StyledSection>
    </>
  );
};

export default PrivacyPolicy;