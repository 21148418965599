import React from "react";
import { useHistory } from "react-router-dom";

import { PrimaryButtonXL } from "../../lib/Buttons";

const NavigationButton = ({ route, label }) => {
  const history = useHistory();

  return (
    <PrimaryButtonXL onClick={() => history.push(`/${route}`)} buttonText={label}></PrimaryButtonXL>
  );
};

export default NavigationButton;