import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { user } from "../../reducers/user";

import {
  HomeIcon,
  BoltIcon,
  ChartBarIcon,
  PhoneIcon,
  DevicePhoneMobileIcon,
  UserCircleIcon
} from "@heroicons/react/24/solid";

import {
  ArrowLeftStartOnRectangleIcon,
  ArrowLeftEndOnRectangleIcon
} from "@heroicons/react/24/outline";

import { StyledMenu } from "../../lib/Styling";

const Menu = ({ open }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const permissionLevel = useSelector((store) => store.user.profile.permissionLevel);

  // Determine icon and link text based on permissionLevel
  const icon = permissionLevel === "demo" 
    ? <ArrowLeftEndOnRectangleIcon className="inline h-5 w-5 -mt-2 stroke-[2.5px]"/> 
    : <ArrowLeftStartOnRectangleIcon className="inline h-5 w-5 -mt-2 stroke-[2.5px]"/>
  const linkText = permissionLevel === "demo" ? "Skapa konto" : "Logga ut"

  const handleLogout = () => {
    dispatch(user.actions.restart());
    localStorage.clear();
    if (permissionLevel === "demo") {
      history.push("/inloggning?mode=createAccount");
    } else {
      history.push("/inloggning");
    }
  };

  return (
    <StyledMenu open={open}>
      <NavLink exact to="/">
        <HomeIcon className="inline h-5 w-5 -mt-2"/> Start
      </NavLink>
      <NavLink to="/anfallsdata">
        <BoltIcon className="inline h-5 w-5 -mt-2"/> Anfallsdata
      </NavLink>
      <NavLink to="/statistik">
        <ChartBarIcon className="inline h-5 w-5 -mt-2" /> Statistik
      </NavLink>
      <NavLink to="/kontakter" exact>
        <PhoneIcon className="inline h-5 w-5 -mt-2" /> Kontakter
      </NavLink>
      <NavLink to="/bakgrundsbild">
        <DevicePhoneMobileIcon className="inline h-5 w-5 -mt-2" /> Bakgrund
      </NavLink>
      <NavLink to="/profil">
        <UserCircleIcon className="inline h-5 w-5 -mt-2" /> Profil
      </NavLink>
      {permissionLevel &&
        <button onClick={handleLogout}>
          {icon} {linkText}
        </button>
      }
    </StyledMenu>
  );
};

export default Menu;