import React from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import locale from "dayjs/locale/sv";
import swal from "sweetalert";

import { deleteSeizure } from "../../reducers/reusable";
import { user } from "../../reducers/user";

import { StyledCard, StyledGrid, StyledCardText } from "../../lib/Styling";
import { SecondaryButtonL } from "../../lib/Buttons";
import NavigationButtonWithProps from "../buttons/NavigationButtonWithProps";

const Seizure = ({ seizure, SEIZURES_URL }) => {
  const dispatch = useDispatch()
  const localToken = localStorage.getItem("localToken");
  const localId = localStorage.getItem("localId");
  const permissionLevel = useSelector((store) => store.user.profile.permissionLevel);
  const seizureId = seizure._id;
  const hours = Math.floor(seizure.duration / 3600);
  const minutes = Math.floor((seizure.duration % 3600) / 60);
  const seconds = (seizure.duration % 3600) % 60;

  const handleDeleteSeizure = (event) => {
    event.preventDefault();
    swal({
      title: "Är du säker på att du vill radera din registrering?",
      text: "Det går inte att återskapa registreringen när den väl är raderad.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          if (permissionLevel === "demo") {
            dispatch(user.actions.deleteSeizure(seizure));
          } else {
            dispatch(deleteSeizure(SEIZURES_URL, localToken, localId, seizureId));
          }
          swal({
            title: "Registrering raderad",
            icon: "success",
          });
        };
      });
  };

  return (
    <StyledCard>
      <StyledGrid>
        <StyledCardText left>Datum:</StyledCardText>
        <StyledCardText>{dayjs(seizure.date).locale({ ...locale }).format("ddd DD MMM HH:mm")}</StyledCardText>
        <StyledCardText left>Längd:</StyledCardText>
        <StyledCardText>{hours}h {minutes}m {seconds}s</StyledCardText>
        <StyledCardText left>Typ:</StyledCardText>
        <StyledCardText>{seizure.type}</StyledCardText>
        <StyledCardText left>Utlösare:</StyledCardText>
        <StyledCardText>{seizure.trigger}</StyledCardText>
        <StyledCardText left>Kommentar:</StyledCardText>
        <StyledCardText>{seizure.comment}</StyledCardText>
      </StyledGrid>
      <SecondaryButtonL danger buttonText="Radera" onClick={handleDeleteSeizure} />
      <NavigationButtonWithProps label="Ändra" route="anfallsregistrering" extraProps={{ seizureToEdit: {seizure} }}/>
    </StyledCard>
  );
};

export default Seizure;