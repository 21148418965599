import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/sv";
import weekOfYear from "dayjs/plugin/weekOfYear";

import {
  HomeIcon,
  BoltIcon,
  ChartBarIcon,
  PhoneIcon,
  DevicePhoneMobileIcon,
  UserCircleIcon
} from "@heroicons/react/24/solid";

import {
  Bars3Icon,
  ArrowLeftStartOnRectangleIcon,
  ArrowPathIcon
} from "@heroicons/react/24/outline";

import { fetchUserData } from "../reducers/reusable";

import NavigationButton from "./buttons/NavigationButton";

import { StyledSection, StyledTitle, StyledSubTitle, StyledSubSection } from "../lib/Styling";
import Accordion from "../lib/Accordion";

dayjs.locale('sv');
dayjs.extend(weekOfYear);

const StartPage = ({ USERDATA_URL, LOGIN_URL, USERS_URL }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const localToken = localStorage.getItem("localToken");
  const localId = localStorage.getItem("localId");
  // const localFirstName = localStorage.getItem("localFirstName");
  const firstName = useSelector((store) => store.user.profile.firstName);
  const storedId = useSelector((store) => store.user.profile.userId);
  const permissionLevel = useSelector((store) => store.user.profile.permissionLevel);
  const isLoading = useSelector((store) => store.ui.isLoading);
  const seizures = useSelector((store) => store.user.seizures);

  const numberOfSeizuresToday = seizures?.filter((item) => dayjs(item.date).format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD")).length ?? 0;
  const numberOfSeizuresThisWeek = seizures?.filter((item) => dayjs(item.date).year() + "-" + dayjs(item.date).week() === dayjs().year() + "-" + dayjs().week()).length ?? 0;
  const numberOfSeizuresThisMonth = seizures?.filter((item) => dayjs(item.date).format("YYYY-MM") === dayjs().format("YYYY-MM")).length ?? 0;

  const faqArray = [
    {
      type: "list-none",
      question: "Hur använder jag appen?",
      answers: [
        <>Klicka på menyn <Bars3Icon className="inline h-5 w-5 -mt-1 stroke-[3.5px]"/> högst upp till vänster för att navigera mellan de olika sidorna.</>,
        <>Klicka på <HomeIcon className="inline h-5 w-5 -mt-2"/> <strong>Start</strong> för att komma tillbaka till den här startsidan.</>,
        <>Under <BoltIcon className="inline h-5 w-5 -mt-2"/> <strong>Anfallsdata</strong> kan du registrera dina anfall och ladda ner din data.</>,
        <>Under <ChartBarIcon className="inline h-5 w-5 -mt-2"/> <strong>Statistik</strong> kan du få en överblick över din anfallsstatistik.</>,
        <>Under <PhoneIcon className="inline h-5 w-5 -mt-2"/> <strong>Kontakter</strong> kan du registrera dina nöd- och sjukvårdskontakter.</>,
        <>Under <DevicePhoneMobileIcon className="inline h-5 w-5 -mt-2"/> <strong>Bakgrund</strong> kan du skapa en bakgrundsbild till din mobil med dina nödkontakter.</>,
        <>Under <UserCircleIcon className="inline h-5 w-5 -mt-2"/> <strong>Profil</strong> kan du uppdatera dina personuppgifter och radera ditt konto.</>,
        <>Klicka på <ArrowLeftStartOnRectangleIcon className="inline h-5 w-5 -mt-2 stroke-[2.5px]"/> <strong>Logga ut</strong> för att logga ut från applikationen.</>,
        <>Klicka på <ArrowPathIcon className="inline h-5 w-5 -mt-2 stroke-[2.5px]"/> högst upp till höger för att ladda om din data från molnet.</>,
      ]
    }]
  
  // Hook for fetching user data
  useEffect(() => {
    if (!storedId && localId && localToken) {
      dispatch(fetchUserData(USERDATA_URL, localToken, localId));
    }
  }, [storedId, localId, localToken, dispatch, USERDATA_URL]);

  // Hook for handling navigation
  useEffect(() => {
    if (!permissionLevel && !localToken) {
      history.push("/inloggning");
    }
  }, [permissionLevel, localToken, history]);

  return (
    <>
      {!isLoading &&
        <StyledSection center>
          <StyledTitle>
            {firstName
              ? `Hej ${firstName}!`
              : "Välkommen till EP App!"}
          </StyledTitle>
          <StyledSection center>
            <StyledSubSection center>
              <StyledSubTitle><strong>{numberOfSeizuresToday}</strong> anfall idag</StyledSubTitle>
              <StyledSubTitle><strong>{numberOfSeizuresThisWeek}</strong> anfall den här veckan</StyledSubTitle>
              <StyledSubTitle><strong>{numberOfSeizuresThisMonth}</strong> anfall den här månaden</StyledSubTitle>
            </StyledSubSection>
            <NavigationButton label="Registrera nytt anfall" route="anfallsregistrering" />
            <StyledSubSection className="mt-8">
            {faqArray.map((faq, index) => {
              return (<Accordion key={index} faq={faq}></Accordion>)
            })
            }
            </StyledSubSection>
          </StyledSection>
        </StyledSection>
      }
    </>
  );
};

export default StartPage;