import React from "react";
import { useDispatch } from "react-redux";

import { fetchUserData } from "../../reducers/reusable";

import { ArrowPathIcon } from "@heroicons/react/24/outline";

const ReloadIcon = ({ USERDATA_URL }) => {
  const dispatch = useDispatch();
  const localToken = localStorage.getItem("localToken");
  const localId = localStorage.getItem("localId");

  const handleReload = (event) => {
    event.preventDefault();
    dispatch(fetchUserData(USERDATA_URL, localToken, localId));
  };

  return (
    <button className="text-inherit bg-inherit" onClick={handleReload}>
      <ArrowPathIcon className="h-8 w-8 stroke-2" />
    </button>
  );
};

export default ReloadIcon;