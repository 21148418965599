export const PALETTE = {
  color1: "#1a2c5b", // dark blue
  color2: "#2b4b87", // regular blue
  color3: "#431488", // dark purple
  color4: "#b8dff0", // light blue
  color5: "#ffffff", // white
  color6: "#c0c0c0", // silver
  color7: "#e5e5e5", // light grey
  color8: "#6a26cd", // regular purple
  color9: "#808080", // grey
  primary050: "hsla(270, 100%, 98%, 1)", // Tailwind purple 50 #FAF5FF
  primary100: "hsla(269, 100%, 95%, 1)", // Tailwind purple 100 #F3E8FF
  primary200: "hsla(269, 100%, 92%, 1)", // Tailwind purple 200 #E9D5FF
  primary300: "hsla(269, 97%, 85%, 1)", // Tailwind purple 300 #D8B4FE
  primary400: "hsla(270, 95%, 75%, 1)", // Tailwind purple 400 #C084FC
  primary500: "hsla(271, 91%, 65%, 1)", // Tailwind purple 500 #A855F7
  primary600: "hsla(271, 81%, 56%, 1)", // Tailwind purple 600 #9333EA
  primary700: "hsla(272, 72%, 47%, 1)", // Tailwind purple 700 #7E22CE
  primary800: "hsla(273, 67%, 37%, 1)", // Tailwind purple 800 #6B21A8
  primary900: "hsla(274, 66%, 32%, 1)", // Tailwind purple 900 #581C87
  // primary050: "hsla(250, 100%, 98%, 1)", // Tailwind violet 50
  // primary100: "hsla(251, 91%, 95%, 1)", // Tailwind violet 100
  // primary200: "hsla(251, 95%, 92%, 1)", // Tailwind violet 200
  // primary300: "hsla(253, 95%, 85%, 1)", // Tailwind violet 300
  // primary400: "hsla(255, 92%, 76%, 1)", // Tailwind violet 400
  // primary500: "hsla(258, 90%, 66%, 1)", // Tailwind violet 500
  // primary600: "hsla(262, 83%, 58%, 1)", // Tailwind violet 600
  // primary700: "hsla(263, 70%, 50%, 1)", // Tailwind violet 700
  // primary800: "hsla(263, 69%, 42%, 1)", // Tailwind violet 800
  // primary900: "hsla(263, 67%, 35%, 1)", // Tailwind violet 900
  // primary050: "#EAE2F8",
  // primary100: "#CFBCF2",
  // primary200: "#A081D9",
  // primary300: "#8662C7",
  // primary400: "#724BB7",
  // primary500: "#653CAD",
  // primary600: "#51279B",
  // primary700: "#421987",
  // primary800: "#34126F",
  // primary900: "#240754",
  grey050: "hsla(210, 40%, 98%, 1)", // Tailwind slate 50 #F8FAFC
  grey100: "hsla(210, 40%, 96%, 1)", // Tailwind slate 100 #F1F5F9
  grey200: "hsla(214, 32%, 91%, 1)", // Tailwind slate 200 #E2E8F0
  grey300: "hsla(213, 27%, 84%, 1)", // Tailwind slate 300 #CBD5E1
  grey400: "hsla(215, 20%, 65%, 1)", // Tailwind slate 400 #94A3B8
  grey500: "hsla(215, 16%, 47%, 1)", // Tailwind slate 500 #64748B
  grey600: "hsla(215, 19%, 35%, 1)", // Tailwind slate 600 #475569
  grey700: "hsla(215, 25%, 27%, 1)", // Tailwind slate 700 #334155
  grey800: "hsla(217, 33%, 17%, 1)", // Tailwind slate 800 #1E293B
  grey900: "hsla(222, 47%, 11%, 1)", // Tailwind slate 900 #0F172A
  // grey050: "#F0F4F8",
  // grey100: "#D9E2EC",
  // grey200: "#BCCCDC",
  // grey300: "#9FB3C8",
  // grey400: "#829AB1",
  // grey500: "#627D98",
  // grey600: "#486581",
  // grey700: "#334E68",
  // grey800: "#243B53",
  // grey900: "#102A43",
  boxShadow: "0px 1px 3px 0px hsla(0, 0%, 0%, 0.3), 0px 4px 8px 3px hsla(0, 0%, 0%, 0.15)",
  type01: "#d8b4fe", // Tailwind purple-300
  type02: "#a855f7", // Tailwind purple-500
  type03: "#7e22ce", // Tailwind purple-700
  type04: "#b45309", // Tailwind amber-700
  type05: "#f59e0b", // Tailwind amber-500
  type06: "#fcd34d", // Tailwind amber-300
  type07: "#047857", // Tailwind emerald-700
  type08: "#10b981", // Tailwind emerald-500
  type09: "#6ee7b7", // Tailwind emerald-300
  type10: "#64748b", // Tailwind slate-500
};