import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { user } from "../../reducers/user";

import { ArrowLeftEndOnRectangleIcon } from "@heroicons/react/24/outline";

const LoginIcon = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const sendToLogin = (event) => {
    event.preventDefault();
    dispatch(user.actions.restart());
    localStorage.clear();
    history.push("/inloggning?mode=createAccount");
  };

  return (
    <button className="text-inherit bg-inherit" onClick={sendToLogin}>
      <ArrowLeftEndOnRectangleIcon className="h-8 w-8 stroke-2" />
    </button>
  );
};

export default LoginIcon;