import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { storeCredentials, storeUserData } from "../../reducers/reusable";
import { user } from "../../reducers/user";

import {
  EyeIcon,
  EyeSlashIcon
} from "@heroicons/react/24/outline";

const Login = ({ LOGIN_URL, USERS_URL }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState (new URLSearchParams(location.search).get('mode') || "login");
  const [showPassword, setShowPassword] = useState("");

  const isFormValid = email && password;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const modeParam = searchParams.get("mode");
    if (modeParam) {
      setMode(modeParam);
    }
  }, [location.search])

  const handleSubmit = (event) => {
    event.preventDefault();
    setResponse("");
    setIsLoading(true);

    const url = mode === "login" ? LOGIN_URL : USERS_URL;
    const successMessage = mode === "login" ? "Inloggning lyckades" : "Konto skapat";

    fetch(url, {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        setIsLoading(false);
        if (!res.ok) {
          if (mode === "login") {
            setResponse("Ogiltiga inloggningsuppgifter, försök igen!");
            throw new Error("Ogiltiga uppgifter");
          }
          setResponse("Det finns redan ett konto kopplat till den här e-postadressen.");
          throw new Error("Kontot finns redan");
        }
        return res.json();
      })
      .then((json) => {
        setResponse(successMessage);
        storeCredentials(json);
        dispatch(storeUserData(json));
        setEmail("");
        setPassword("");
        setRepeatPassword("");
        history.push("/");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Function to reset error message
  const handleChange = () => {
    setMode(mode === "login" ? "createAccount" : "login");
    setResponse("");
  };

  const handleDemoMode = () => {
    localStorage.clear();
    dispatch(user.actions.restart());
    dispatch(user.actions.activateDemoMode());
    history.push("/");
  };

  return (
    <>
      <div className="flex flex-1 flex-col py-6 sm:justify-center sm:py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-16 w-auto"
            src="https://app.epapp.se/logo192.png"
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight">
            {mode === "login" ? "Logga in" : "Skapa konto"}
          </h2>
        </div>

        <div className="mt-4 sm:mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-6 shadow sm:rounded-lg sm:px-12 sm:py-12">
          <form className="space-y-4 sm:space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6">
                  E-postadress
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    minLength="5"
                    name="email"
                    type="email"
                    value={email}
                    autoComplete="email"
                    required
                    onChange={event => setEmail(event.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium leading-6">
                  Lösenord
                </label>
                <div className="mt-2 relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    required
                    minLength="5"
                    value={password}
                    onChange={event => setPassword(event.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  >
                    {showPassword ? (<EyeSlashIcon className="h-5 w-5 text-slate-700"/>) : (<EyeIcon className="h-5 w-5 text-slate-700" />)}
                  </button>
                </div>
              </div>

              {mode === "createAccount" && (
                <div>
                  <label htmlFor="repeatPassword" className="block text-sm font-medium leading-6">
                    Upprepa lösenord
                  </label>
                  <div className="mt-2 relative">
                    <input
                      id="repeatPassword"
                      name="repeatPassword"
                      type={showPassword ? "text" : "password"}
                      required
                      value={repeatPassword}
                      onChange={event => setRepeatPassword(event.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              )}
              
              {((password !== repeatPassword) && (mode === "createAccount")) && <p className="text-purple-700 italic text-center text-sm mt-2">Lösenorden matchar inte!</p>}
              {response && <p className="text-purple-700 italic text-center text-sm mt-2">{response}</p>}
              
              <div>
                <button
                  type="submit"
                  disabled={!isFormValid || isLoading || (mode === "createAccount" && password !== repeatPassword)}
                  onClick={handleSubmit}
                  className="flex w-full justify-center rounded-md bg-purple-900 px-3 py-1.5 text-m font-normal leading-6 text-white shadow-sm hover:bg-purple-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-900"
                >
                  {isLoading ? "Laddar..." : mode === "login" ? "Logga in" : "Skapa konto"}
                </button>
              </div>
            </form>
            <div>
              <button
                onClick={handleDemoMode}
                className="flex w-full justify-center rounded-md bg-slate-100 mt-4 sm:mt-6 px-3 py-1.5 text-m font-bold leading-6 text-purple-900 shadow-sm hover:bg-slate-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-900"
              >
                Se demo
              </button>
            </div>
          </div>

          <p className="mt-4 text-center text-sm sm:mt-10">
            {mode === "login" ? "Har du inget konto än? " : "Har du redan ett konto? "}
            <button 
              className="font-semibold leading-6 text-purple-700 hover:text-purple-500"
              onClick={() => handleChange()}>
              {mode === "login" ? "Skapa ett här." : "Logga in istället."}
            </button>
          </p>

          <p className="mt-4 text-center text-xs sm:mt-10">Genom att använda Ep App samtycker du till att vi hanterar dina personuppgifter. Läs mer i vår <a className="text-purple-700 underline" href="./integritetspolicy" target="_blank" rel="noopener noreferrer">Integritetspolicy</a>.</p>

        </div>
      </div>
    </>
  );
};

export default Login;