import React from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";

import { deleteContact } from "../../reducers/reusable";
import { user } from "../../reducers/user";
import { useToggle } from "../../reducers/reusable";

import ContactForm from "./ContactForm";

import { StyledCard, StyledGrid, StyledCardLink, StyledCardText } from "../../lib/Styling";
import { SecondaryButtonL } from "../../lib/Buttons";

const Contact = ({ contact, CONTACTS_URL }) => {
  const dispatch = useDispatch()
  const [editMode, toggleEditMode] = useToggle();
  const localToken = localStorage.getItem("localToken");
  const localId = localStorage.getItem("localId");
  const permissionLevel = useSelector((store) => store.user.profile.permissionLevel);
  const contactId = contact._id;

  const handleDeleteContact = (event) => {
    event.preventDefault();
    swal({
      title: "Är du säker på att du vill radera kontakten?",
      text: "Det går inte att återskapa kontakten när den väl är raderad.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          if (permissionLevel === "demo") {
            dispatch(user.actions.deleteContact(contact));
          } else {
            dispatch(deleteContact(CONTACTS_URL, localToken, localId, contactId));
          }
          swal({
            title: "Kontakt raderad",
            icon: "success",
          });
        };
      });
  };

  const callHandler = phoneNumber => {
    window.open(`tel:${phoneNumber}`, "_self")
  }

  return (
    <>
      {editMode
        ? <ContactForm CONTACTS_URL={CONTACTS_URL} contact={contact} toggleEditMode={toggleEditMode} />
        : (
          <StyledCard>
            <StyledGrid>
              <StyledCardText left>Typ:</StyledCardText>
              <StyledCardText>{contact.type}</StyledCardText>
              <StyledCardText left>Förnamn:</StyledCardText>
              <StyledCardText>{contact.firstName}</StyledCardText>
              <StyledCardText left>Efternamn:</StyledCardText>
              <StyledCardText>{contact.surname}</StyledCardText>
              <StyledCardText left>Telefonnr:</StyledCardText>
              <StyledCardLink onClick={() => callHandler(contact.phoneNumber)}>{contact.phoneNumber}</StyledCardLink>
              <StyledCardText left>Relation:</StyledCardText>
              <StyledCardText>{contact.category}</StyledCardText>
            </StyledGrid>
            <SecondaryButtonL danger buttonText="Radera" onClick={handleDeleteContact} />
            <SecondaryButtonL normal buttonText="Ändra" onClick={toggleEditMode} />
          </StyledCard>
        )
      }
    </>
  );
};

export default Contact;