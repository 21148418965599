import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';

import { registerContact } from "../../reducers/reusable";
import { user } from "../../reducers/user";
import contactList from "../../assets/contact-types.json";

import { StyledForm, StyledCardInput, StyledCardLabel, StyledCard, StyledCardSelect, StyledGrid } from "../../lib/Styling";
import { PrimaryButtonL, SecondaryButtonL } from "../../lib/Buttons";

const ContactRegistration = ({ CONTACTS_URL, toggleCreationMode }) => {
  const dispatch = useDispatch();
  // const storedContactTypes = useSelector((store) => store.user.contactTypes);
  const storedContactTypes = contactList;
  const localToken = localStorage.getItem("localToken");
  const localId = localStorage.getItem("localId");
  const permissionLevel = useSelector((store) => store.user.profile.permissionLevel);
  const [type, setType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [category, setCategory] = useState("");

  const newContact = { _id: uuidv4(), type, firstName, surname, phoneNumber, category };

  const handleEdit = (event) => {
    event.preventDefault();
    if (permissionLevel === "demo") {
      dispatch(user.actions.addContact(newContact));
    } else {
      dispatch(registerContact(CONTACTS_URL, localToken, localId, newContact));
    }
    toggleCreationMode();
  };

  return (
    <StyledForm onSubmit={handleEdit}>
      <StyledCard>
        <StyledGrid>
          <StyledCardLabel htmlFor="type">
            Typ:
        </StyledCardLabel>
          <StyledCardSelect
            id="type"
            required
            defaultValue=""
            onChange={event => setType(event.target.value)} >
            <option value="" disabled>Välj typ</option>
            {storedContactTypes.map(type => {
              return (<option key={type.name} value={type.name}>{type.name}</option>)
            })}
          </StyledCardSelect>
          <StyledCardLabel htmlFor="firstname">
            Förnamn:
        </StyledCardLabel>
          <StyledCardInput
            required
            id="firstname"
            minLength="2"
            type="text"
            value={firstName}
            onChange={event => setFirstName(event.target.value)} >
          </StyledCardInput>
          <StyledCardLabel htmlFor="surname">
            Efternamn:
        </StyledCardLabel>
          <StyledCardInput
            id="surname"
            minLength="2"
            type="text"
            value={surname}
            onChange={event => setSurname(event.target.value)} >
          </StyledCardInput>
          <StyledCardLabel htmlFor="phonenumber">
            Telefonnr:
        </StyledCardLabel>
          <StyledCardInput
            required
            id="phonenumber"
            minLength="2"
            type="tel"
            value={phoneNumber}
            onChange={event => setPhoneNumber(event.target.value)} >
          </StyledCardInput>
          {!type && (
            <>
              <StyledCardLabel htmlFor="norelation">
                Relation:
            </StyledCardLabel>
              <StyledCardSelect
                id="norelation"
                required
                defaultValue=""
                onChange={event => setCategory(event.target.value)} >
                <option value="" disabled>Välj typ först!</option>
              </StyledCardSelect>
            </>
          )}
          {type && (
            <>
              <StyledCardLabel htmlFor="relation">
                Relation:
            </StyledCardLabel>
              <StyledCardSelect
                id="relation"
                required
                defaultValue=""
                onChange={event => setCategory(event.target.value)} >
                <option value="" disabled>Välj relation</option>
                {storedContactTypes.find(item => item.name === type).categories.map(category => {
                  return (<option key={category} value={category}>{category}</option>)
                })}
              </StyledCardSelect>
            </>
          )}
        </StyledGrid>
        <SecondaryButtonL normal buttonText="Avbryt" onClick={toggleCreationMode} />
        <PrimaryButtonL type="submit" buttonText="Spara" />
      </StyledCard>
    </StyledForm>
  )
};

export default ContactRegistration;