import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";

import { useOnClickOutside } from "../../reducers/reusable";

import Burger from "./Burger";
import Menu from "./Menu";

import { StyledHeader } from "../../lib/Styling";
import ReloadIcon from "../buttons/ReloadIcon";
import LoginIcon from "../buttons/LoginIcon";

const Header = ({ USERDATA_URL }) => {
  const isFullScreen = useSelector((store) => store.ui.isFullScreen);
  const permissionLevel = useSelector((store) => store.user.profile.permissionLevel);
  const [open, setOpen] = useState(false);
  const node = useRef();

  useOnClickOutside(node, () => {
    setOpen(false);
    document.body.classList.remove("no-scroll");
  });

  return (
    <>{!isFullScreen &&
      <StyledHeader>
        <div ref={node}>
          <Burger open={open} setOpen={setOpen} />
          <Menu open={open} />
        </div>
        EP App
        <div className="h-8 w-8">
          {!permissionLevel ? null :
          permissionLevel === "demo" ? <LoginIcon /> : <ReloadIcon USERDATA_URL={USERDATA_URL}/>}
        </div>
      </StyledHeader>
    }
    </>
  );
};

export default Header;