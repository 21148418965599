import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import swal from "sweetalert";
import dayjs from "dayjs";
import locale from "dayjs/locale/sv";

import { registerSeizure, updateSeizure } from "../../reducers/reusable";
import { user } from "../../reducers/user";
import seizureList from "../../assets/seizure-types.json";

import { StyledSection } from "../../lib/Styling";
import { PrimaryButtonL } from "../../lib/Buttons";
import { XMarkIcon } from "@heroicons/react/24/outline";

const SeizureRegistration = ({ SEIZURES_URL }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const storedSeizureTypes = seizureList;
  const localToken = localStorage.getItem("localToken");
  const localId = localStorage.getItem("localId");
  const permissionLevel = useSelector((store) => store.user.profile.permissionLevel);
  const { seizureToEdit = {} } = location.state || {};
  const d = dayjs().locale({ ...locale }).format("YYYY-MM-DD");
  const t = dayjs().locale({ ...locale }).format("HH:mm");

  const [date, setDate] = useState(d);
  const [time, setTime] = useState(t);
  const [lengthHours, setLengthHours] = useState("0");
  const [lengthMinutes, setLengthMinutes] = useState("0");
  const [lengthSeconds, setLengthSeconds] = useState("0");
  const [type, setType] = useState("");
  const [trigger, setTrigger] = useState("");
  const [comment, setComment] = useState("");

  const isEditMode = seizureToEdit.seizure && seizureToEdit.seizure._id;

  const seizure = { date, time, lengthHours, lengthMinutes, lengthSeconds, type, trigger, comment };
  const localSeizure = {
    _id: isEditMode ? seizureToEdit.seizure._id : uuidv4(),
    date: dayjs(date + "T" + time).format('YYYY-MM-DDTHH:mm:ss.SSS'),
    duration: parseInt(lengthHours) * 3600 + parseInt(lengthMinutes) * 60 + parseInt(lengthSeconds),
    type: type,
    trigger: trigger,
    comment: comment
  }

  useEffect(() => {
    if (isEditMode) {
      setDate(dayjs(seizureToEdit.seizure.date).locale({ ...locale }).format("YYYY-MM-DD"));
      setTime(dayjs(seizureToEdit.seizure.date).locale({ ...locale }).format("HH:mm"));
      setLengthHours(Math.floor(seizureToEdit.seizure.duration / 3600).toString().padStart(2, "0"));
      setLengthMinutes((Math.floor((seizureToEdit.seizure.duration % 3600) / 60)).toString().padStart(2, "0"));
      setLengthSeconds(((seizureToEdit.seizure.duration % 3600) % 60).toString().padStart(2, "0"));
      setType(seizureToEdit.seizure.type);
      setTrigger(seizureToEdit.seizure.trigger);
      setComment(seizureToEdit.seizure.comment);
    }
  }, [seizureToEdit, isEditMode]);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEditMode) {
      swal({
        title: "Är du säker på att du vill spara dina ändringar?",
        text: "Det går inte att ångra ändringen när den väl är sparad.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then ((willSave) => {
        if (willSave) {
          if (permissionLevel === "demo") {
            dispatch(user.actions.updateSeizure(localSeizure));
          } else {
            dispatch(updateSeizure(SEIZURES_URL, localToken, localId, seizure, seizureToEdit.seizure._id));
          }
          history.push("/anfallsdata");
        }
      })
    } else {
      if (permissionLevel === "demo") {
        dispatch(user.actions.addSeizure(localSeizure));
      } else {
        dispatch(registerSeizure(SEIZURES_URL, localToken, localId, seizure));
      }
      history.push("/anfallsdata");
    }
  };

  return (
    <StyledSection>
      {/* Close button */}
      <button className="self-end" onClick={() => history.goBack()}>
        <XMarkIcon className="h-8 w-8 stroke-2 my-2" />
      </button>

      {/* Title */}
      <h1 className="pb-4 text-center text-2xl font-bold">
        {isEditMode ? "Uppdatera anfall" : "Nytt anfall"}
      </h1>

      {/* Registration form */}
      <form className="flex flex-col" onSubmit={handleSubmit}>
        {/* Containers */}
        <div className="my-2 space-y-12 sm:space-y-16">
          <div className="space-y-8 sm:space-y-0 sm:pb-0">

            {/* Date */}
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label htmlFor="date" className="block font-medium leading-6 text-gray-900 sm:pt-1.5">
                Datum
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <input
                  type="date"
                  id="date"
                  placeholder="yyyy-mm-dd"
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                  name="date"
                  value={date}
                  onChange={event => setDate(event.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            {/* Time */}
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label htmlFor="time" className="block font-medium leading-6 text-gray-900 sm:pt-1.5">
                Tid
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <input
                  type="time"
                  id="time"
                  placeholder="Thh:mm"
                  pattern="[0-9]{2}:[0-9]{2}"
                  name="time"
                  value={time}
                  onChange={event => setTime(event.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            {/* Duration */}
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <div className="block font-medium leading-6 text-gray-900 sm:pt-1.5">
                Längd
              </div>

              <div className="flex gap-x-2 smt-2 sm:col-span-2 sm:mt-0">
                {/* Duration in hours */}
                <select
                  aria-label="hours"
                  name="lengthHours"
                  value={lengthHours}
                  onChange={event => setLengthHours(event.target.value)}
                  className="block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"
                >
                {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
                <option key={hour} value={hour.toString().padStart(2, "0")}>
                  {hour.toString().padStart(2, "0") + " tim"}
                </option>
                ))}
                </select>

                {/* Duration in minutes */}
                <select
                  aria-label="minutes"
                  name="lengthMinutes"
                  value={lengthMinutes}
                  onChange={event => setLengthMinutes(event.target.value)}
                  className="block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"
                >
                {Array.from({ length: 60 }, (_, i) => i).map((minute) => (
                <option key={minute} value={minute.toString().padStart(2, "0")}>
                {minute.toString().padStart(2, "0") + " min"}
                </option>
                ))}
                </select>

                {/* Duration in seconds */}                
                <select
                  aria-label="seconds"
                  name="lengthSeconds"
                  value={lengthSeconds}
                  onChange={event => setLengthSeconds(event.target.value)}
                  className="block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"
                >
                {Array.from({ length: 60 }, (_, i) => i).map((second) => (
                <option key={second} value={second.toString().padStart(2, "0")}>
                  {second.toString().padStart(2, "0") + " sek"}
                </option>
                ))}
                </select>
              </div>
            </div>

            {/* Type */}
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label htmlFor="type" className="block font-medium leading-6 text-gray-900 sm:pt-1.5">
                Typ
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <select
                  id="type"
                  required
                  name="type"
                  value={type}
                  onChange={event => setType(event.target.value)}
                  className="block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                <option value="" disabled>Välj typ</option>
                {storedSeizureTypes.map(type => {
                  return (<option key={type.name} value={type.name}>{type.name}</option>)
                })}
                </select>
              </div>
            </div>

            {/* Trigger */}
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label htmlFor="trigger" className="block font-medium leading-6 text-gray-900 sm:pt-1.5">
                Utlösare
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <input
                  type="text"
                  id="trigger"
                  name="trigger"
                  value={trigger}
                  onChange={event => setTrigger(event.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xl sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            {/* Comment */}
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label htmlFor="comment" className="block font-medium leading-6 text-gray-900 sm:pt-1.5">
                Kommentar
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <textarea
                  id="comment"
                  rows={4}
                  name="comment"
                  value={comment}
                  onChange={event => setComment(event.target.value)}
                  className="block w-full max-w-2xl rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

          </div>
        </div>
        {/* Submit button */}
        <PrimaryButtonL type="submit" buttonText="Spara" />
      </form>
    </StyledSection>
  )
};

export default SeizureRegistration;