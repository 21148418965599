export const PrimaryButtonXS = (props) => {
  return (
    <button
      type={props.type}
      className="rounded bg-purple-900 my-2.5 mx-3.5 py-1 px-2 text-xs font-normal text-white shadow-md hover:bg-purple-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-900"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.buttonText}
    </button>
  )
};

export const PrimaryButtonS = (props) => {
  return (
    <button
      type={props.type}
      className="rounded bg-purple-900 my-2.5 mx-3.5 py-1 px-2 text-sm font-normal text-white shadow-md hover:bg-purple-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-900"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.buttonText}
    </button>
  )
};

export const PrimaryButtonM = (props) => {
  return (
    <button
      type={props.type}
      className="rounded-md bg-purple-900 my-2.5 mx-3.5 py-1.5 px-2.5 text-sm font-normal text-white shadow-md hover:bg-purple-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-900"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.buttonText}
    </button>
  )
};

export const PrimaryButtonL = (props) => {
  return (
    <button
      className="rounded-md bg-purple-900 my-2.5 mx-3.5 py-2 px-3 text-m min-w-[40%] font-normal text-white shadow-md hover:bg-purple-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-900"
      onClick={props.onClick}
      disabled={props.disabled}
      type={props.type}
    >
      {props.buttonText}
    </button>
  )
};

export const PrimaryButtonXL = (props) => {
  return (
    <button
      type={props.type}
      className="rounded-md bg-purple-900 my-2.5 mx-3.5 py-2.5 px-3.5 text-m font-normal text-white shadow-md hover:bg-purple-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-900"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.buttonText}
    </button>
  )
};

export const SecondaryButtonXS = (props) => {
  return (
    <button
      type={props.type}
      className={"rounded bg-slate-50 my-2.5 mx-3.5 py-1 px-2 text-xs font-normal text-purple-900 shadow-md ring-1 ring-inset ring-slate-300 " + (props.danger && "hover:bg-purple-900 hover:text-white") + " " + (props.disabled && "disabled:text-slate-300 hover:bg-slate-50") + " " + (props.normal && "hover:bg-slate-100")}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.buttonText}
    </button>
  )
};

export const SecondaryButtonS = (props) => {
  return (
    <button
      type={props.type}
      className={"rounded bg-slate-50 my-2.5 mx-3.5 py-1 px-2 text-sm font-normal text-purple-900 shadow-md ring-1 ring-inset ring-slate-300 " + (props.danger && "hover:bg-purple-900 hover:text-white") + " " + (props.disabled && "disabled:text-slate-300 hover:bg-slate-50") + " " + (props.normal && "hover:bg-slate-100")}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.buttonText}
    </button>
  )
};

export const SecondaryButtonM = (props) => {
  return (
    <button
      type={props.type}
      className={"rounded-md bg-slate-50 my-2.5 mx-3.5 py-1.5 px-2.5 text-sm font-normal text-purple-900 shadow-md ring-1 ring-inset ring-slate-300 " + (props.danger && "hover:bg-purple-900 hover:text-white") + " " + (props.disabled && "disabled:text-slate-300 hover:bg-slate-50") + " " + (props.normal && "hover:bg-slate-100")}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.buttonText}
    </button>
  )
};

export const SecondaryButtonL = (props) => {
  return (
    <button
      type={props.type}
      className={"rounded-md bg-slate-50 my-2.5 mx-3.5 py-2 px-3 text-m min-w-[40%] font-normal text-purple-900 shadow-md ring-1 ring-inset ring-slate-300 " + (props.danger && "hover:bg-purple-900 hover:text-white") + " " + (props.disabled && "disabled:text-slate-300 hover:bg-slate-50") + " " + (props.normal && "hover:bg-slate-100")}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.buttonText}
    </button>
  )
};

export const SecondaryButtonXL = (props) => {
  return (
    <button
      type={props.type}
      className={"rounded-md bg-slate-50 my-2.5 mx-3.5 py-2.5 px-3.5 text-m font-normal text-purple-900 shadow-md ring-1 ring-inset ring-slate-300 " + (props.danger && "hover:bg-purple-900 hover:text-white") + " " + (props.disabled && "disabled:text-slate-300 hover:bg-slate-50") + " " + (props.normal && "hover:bg-slate-100")}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.buttonText}
    </button>
  )
};

