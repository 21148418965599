import { useEffect, useState, useCallback } from "react";
import dayjs from "dayjs";

import { user } from "./user";
import { ui } from "./ui";

var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

export const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);

  const toggle = useCallback(() => {
    setValue(v => !v);
  }, []);

  return [value, toggle];
};

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

export const storeCredentials = (json) => {
  localStorage.setItem("localToken", json.accessToken);
  localStorage.setItem("localId", json.userId);
  localStorage.setItem("localFirstName", json.firstName);
};

export const storeUserProfile = (json) => {
  return (dispatch) => {
    dispatch(user.actions.setAccessToken({ accessToken: json.accessToken }));
    dispatch(user.actions.setUserId({ userId: json.userId }));
    dispatch(user.actions.setEmail({ email: json.email }));
    dispatch(user.actions.setFirstName({ firstName: json.firstName }));
    dispatch(user.actions.setSurname({ surname: json.surname }));
    dispatch(user.actions.setBirthDate({ birthDate: json.birthDate }));
    dispatch(user.actions.setPermissionLevel({ permissionLevel: json.permissionLevel }));
    // dispatch(user.actions.setSeizureTypes({ seizureTypes: json.seizureTypes }));
    // dispatch(user.actions.setContactTypes({ contactTypes: json.contactTypes }));
  }
};

export const storeUserData = (json) => {
  return (dispatch) => {
    dispatch(user.actions.setAccessToken({ accessToken: json.accessToken }));
    dispatch(user.actions.setUserId({ userId: json.userId }));
    dispatch(user.actions.setEmail({ email: json.email }));
    dispatch(user.actions.setFirstName({ firstName: json.firstName }));
    dispatch(user.actions.setSurname({ surname: json.surname }));
    dispatch(user.actions.setBirthDate({ birthDate: json.birthDate }));
    dispatch(user.actions.setPermissionLevel({ permissionLevel: json.permissionLevel }));
    dispatch(user.actions.setSeizures({ seizures: json.seizures }));
    dispatch(user.actions.setContacts({ contacts: json.contacts }));
    // dispatch(user.actions.setSeizureTypes({ seizureTypes: json.seizureTypes }));
    // dispatch(user.actions.setContactTypes({ contactTypes: json.contactTypes }));
  }
};

export const fetchUserData = (USERDATA_URL, localToken, localId) => {
  return (dispatch) => {
    dispatch(ui.actions.setLoading(true));
    fetch(USERDATA_URL, {
      method: "GET",
      headers: { Authorization: localToken, userId: localId },
    })
      .then(response => response.json())
      .then(json => {
        dispatch(storeUserData(json));
        dispatch(ui.actions.setLoading(false));
      })
      .catch(error => console.error(error));
  };
};

export const updateProfile = (USERDATA_URL, localToken, localId, updatedProfile) => {
  return (dispatch) => {
    dispatch(ui.actions.setLoading(true));
    fetch(USERDATA_URL, {
      method: "PATCH",
      body: JSON.stringify({
        email: updatedProfile.email,
        firstName: updatedProfile.firstName,
        surname: updatedProfile.surname,
        birthDate: updatedProfile.birthDate
      }),
      headers: { "Content-Type": "application/json", Authorization: localToken, userId: localId },
    })
      .then(response => response.json())
      .then(json => {
        storeCredentials(json);
        dispatch(storeUserProfile(json));
        dispatch(ui.actions.setLoading(false));
      })
      .catch(error => console.error(error));
  };
};

export const registerSeizure = (SEIZURES_URL, localToken, localId, newSeizure) => {
  return (dispatch) => {
    dispatch(ui.actions.setLoading(true));

    // This is the common payload structure
    const payload = {
      duration: parseInt(newSeizure.lengthHours) * 3600 + parseInt(newSeizure.lengthMinutes) * 60 + parseInt(newSeizure.lengthSeconds),
      type: newSeizure.type,
      trigger: newSeizure.trigger,
      comment: newSeizure.comment
    };

    // If the date is provided, add it to the payload
    if (newSeizure.date) {
      payload.date = dayjs(newSeizure.date + newSeizure.time);
    } else {
      // If no date is provided, use the current time as the date
      payload.date = dayjs();
    }
    fetch(SEIZURES_URL, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: { "Content-Type": "application/json", Authorization: localToken, userId: localId },
    })
    .then(response => response.json())
    .then(json => {
      dispatch(user.actions.addSeizure(json));
      dispatch(ui.actions.setLoading(false));
    })
    .catch(error => {
      console.error(error);
      dispatch(ui.actions.setLoading(false));
    });
  };
};

export const registerContact = (CONTACTS_URL, localToken, localId, newContact) => {
  return (dispatch) => {
    dispatch(ui.actions.setLoading(true));
    fetch(CONTACTS_URL, {
      method: "POST",
      body: JSON.stringify({
        type: newContact.type,
        firstName: newContact.firstName,
        surname: newContact.surname,
        phoneNumber: newContact.phoneNumber,
        category: newContact.category
      }),
      headers: { "Content-Type": "application/json", Authorization: localToken, userId: localId },
    })
      .then(response => response.json())
      .then(json => {
        dispatch(user.actions.addContact(json));
        dispatch(ui.actions.setLoading(false));
      })
      .catch(error => console.error(error));
  };
};

// export const registerSeizureType = (SEIZURETYPES_URL, localToken, localId, newSeizureType) => {
//   return (dispatch) => {
//     dispatch(ui.actions.setLoading(true));
//     fetch(SEIZURETYPES_URL, {
//       method: "POST",
//       body: JSON.stringify({
//         name: newSeizureType.name,
//         description: newSeizureType.description,
//         category: newSeizureType.category,
//         standardDuration: dayjs(newSeizureType.standardDuration)
//       }),
//       headers: { "Content-Type": "application/json", Authorization: localToken, userId: localId },
//     })
//       .then(response => response.json())
//       .then(json => {
//         dispatch(user.actions.addSeizureType(json));
//         dispatch(ui.actions.setLoading(false));
//       })
//       .catch(error => console.error(error));
//   };
// };

export const updateSeizure = (SEIZURES_URL, localToken, localId, updatedSeizure, seizureId) => {
  return (dispatch) => {
    dispatch(ui.actions.setLoading(true));
    fetch(SEIZURES_URL, {
      method: "PATCH",
      body: JSON.stringify({
        date: dayjs(updatedSeizure.date + updatedSeizure.time),
        duration: parseInt(updatedSeizure.lengthHours) * 3600 + parseInt(updatedSeizure.lengthMinutes) * 60 + parseInt(updatedSeizure.lengthSeconds),
        type: updatedSeizure.type,
        trigger: updatedSeizure.trigger,
        comment: updatedSeizure.comment
      }),
      headers: { "Content-Type": "application/json", Authorization: localToken, userId: localId, seizureId: seizureId },
    })
      .then(response => response.json())
      .then(json => {
        dispatch(user.actions.updateSeizure(json));
        dispatch(ui.actions.setLoading(false));
      })
      .catch(error => console.error(error));
  };
};

export const updateContact = (CONTACTS_URL, localToken, localId, updatedContact) => {
  return (dispatch) => {
    dispatch(ui.actions.setLoading(true));
    fetch(CONTACTS_URL, {
      method: "PATCH",
      body: JSON.stringify({
        type: updatedContact.type,
        firstName: updatedContact.firstName,
        surname: updatedContact.surname,
        phoneNumber: updatedContact.phoneNumber,
        category: updatedContact.category
      }),
      headers: { "Content-Type": "application/json", Authorization: localToken, userId: localId, contactId: updatedContact._id },
    })
      .then(response => response.json())
      .then(json => {
        dispatch(user.actions.updateContact(json));
        dispatch(ui.actions.setLoading(false));
      })
      .catch(error => console.error(error));
  };
};

// export const updateSeizureType = (SEIZURETYPES_URL, localToken, localId, updatedSeizureType) => {
//   return (dispatch) => {
//     dispatch(ui.actions.setLoading(true));
//     fetch(SEIZURETYPES_URL, {
//       method: "PATCH",
//       body: JSON.stringify({
//         name: updatedSeizureType.name,
//         description: updatedSeizureType.description,
//         category: updatedSeizureType.category,
//         standardDuration: dayjs(updatedSeizureType.standardDuration)
//       }),
//       headers: { "Content-Type": "application/json", Authorization: localToken, userId: localId, seizureTypeId: updatedSeizureType.seizureTypeId },
//     })
//       .then(response => response.json())
//       .then(json => {
//         dispatch(user.actions.addSeizureType(json));
//         dispatch(ui.actions.setLoading(false));
//       })
//       .catch(error => console.error(error));
//   };
// };

export const deleteSeizure = (SEIZURES_URL, localToken, localId, seizureId) => {
  return (dispatch) => {
    dispatch(ui.actions.setLoading(true));
    fetch(SEIZURES_URL, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", Authorization: localToken, userId: localId, seizureId: seizureId },
    })
      .then(response => response.json())
      .then(json => {
        dispatch(user.actions.deleteSeizure(json));
        dispatch(ui.actions.setLoading(false));
      })
      .catch(error => console.error(error));
  };
};

export const deleteContact = (CONTACTS_URL, localToken, localId, contactId) => {
  return (dispatch) => {
    dispatch(ui.actions.setLoading(true));
    fetch(CONTACTS_URL, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", Authorization: localToken, userId: localId, contactId: contactId },
    })
      .then(response => response.json())
      .then(json => {
        dispatch(user.actions.deleteContact(json));
        dispatch(ui.actions.setLoading(false));
      })
      .catch(error => console.error(error));
  };
};

// export const deleteSeizureType = (SEIZURETYPES_URL, localToken, localId, seizureTypeId) => {
//   return (dispatch) => {
//     dispatch(ui.actions.setLoading(true));
//     fetch(SEIZURETYPES_URL, {
//       method: "DELETE",
//       headers: { "Content-Type": "application/json", Authorization: localToken, userId: localId, seizureTypeId: seizureTypeId },
//     })
//       .then(response => response.json())
//       .then(json => {
//         dispatch(user.actions.deleteSeizureType(json));
//         dispatch(ui.actions.setLoading(false));
//       })
//       .catch(error => console.error(error));
//   };
// };