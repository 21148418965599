import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import swal from "sweetalert";

import { fetchUserData } from "../../reducers/reusable";
import { user } from "../../reducers/user";
import { useToggle } from "../../reducers/reusable";

import ProfileCard from "./ProfileCard";
import ProfileForm from "./ProfileForm";

import { StyledTitle, StyledSection, StyledSubSection, StyledEPText, StyledEPLink } from "../../lib/Styling";
import { SecondaryButtonXL } from "../../lib/Buttons";

const Profile = ({ USERDATA_URL }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const localToken = localStorage.getItem("localToken");
  const localId = localStorage.getItem("localId");
  const storedId = useSelector((store) => store.user.profile.userId);
  const permissionLevel = useSelector((store) => store.user.profile.permissionLevel);
  const isLoading = useSelector((store) => store.ui.isLoading);

  const [editMode, toggleEditMode] = useToggle();

  useEffect(() => {
    if (!permissionLevel && !localToken) {
      history.push("/inloggning")
    }
    if (!storedId && localId) {
      dispatch(fetchUserData(USERDATA_URL, localToken, localId));
    }
    // eslint-disable-next-line
  }, []);

  const handleDeleteUser = (event) => {
    event.preventDefault();
    swal({
      title: "Är du säker på att du vill radera ditt konto?",
      text: "Det går inte att återskapa din data när ditt konto väl är raderat.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          fetch(USERDATA_URL, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", Authorization: localToken, userId: localId },
          });
          dispatch(user.actions.restart());
          localStorage.clear();
          history.push("/inloggning");
          swal({
            title: "Profil raderad",
            icon: "success",
          })
        }
      })
  };

  return (
    <>
      {!isLoading &&
        <>
          <StyledSection center>
            <StyledTitle>Profil</StyledTitle>
            <StyledSubSection>
              <StyledEPText>
              Här nedan kan du uppdatera din profilinformation.
              </StyledEPText>
              <StyledEPText>
              Kom ihåg att din e-postadress är kopplad till din inloggning. <br />
              Uppdaterar du e-postadressen nedan är det den nya adressen som gäller nästa gång du loggar in.
              </StyledEPText>
              <StyledEPText>Observera att namnet på bakgrundsbilden hämtas från fälten nedan.</StyledEPText>
            </StyledSubSection>
            {editMode
              ? <ProfileForm USERDATA_URL={USERDATA_URL} toggleEditMode={toggleEditMode} />
              : (
                <>
                <ProfileCard />
                <SecondaryButtonXL normal onClick={toggleEditMode} buttonText="Redigera" />
                </>
            )}
            <StyledEPText>Genom att använda Ep App samtycker du till att vi hanterar dina personuppgifter. Läs mer i vår <StyledEPLink href="../integritetspolicy">Integritetspolicy.</StyledEPLink></StyledEPText>
            {!editMode && <SecondaryButtonXL danger buttonText="Radera konto" onClick={handleDeleteUser} /> }
          </StyledSection>
        </>
      }
    </>
  )
};

export default Profile;