import React from "react";
import { useHistory } from "react-router-dom";

import { PrimaryButtonL } from "../../lib/Buttons";

const NavigationButtonWithProps = ({ route, label, extraProps }) => {
  const history = useHistory();

  return (
    <PrimaryButtonL
      onClick={() => history.push({
        pathname: `/${route}`,
        state: { ...extraProps }
      })}
      buttonText={label}>
    </PrimaryButtonL>
  );
};

export default NavigationButtonWithProps;