import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { v4 as uuidv4 } from 'uuid';

const yesterdayAt22 = dayjs().subtract(1, "day").hour(22).minute(55).second(0).millisecond(0).format('YYYY-MM-DDTHH:mm:ss.SSS');
const dayBeforeAt19 = dayjs().subtract(2, "day").hour(19).minute(5).second(0).millisecond(0).format('YYYY-MM-DDTHH:mm:ss.SSS');
const lastWeekAt14 = dayjs().subtract(1, "week").hour(14).minute(33).second(0).millisecond(0).format('YYYY-MM-DDTHH:mm:ss.SSS');

const _seizureId1 = uuidv4();
const _seizureId2 = uuidv4();
const _seizureId3 = uuidv4();
const _contactId1 = uuidv4();
const _contactId2 = uuidv4();

const initialState = {
  profile: {
    accessToken: "",
    userId: "",
    email: "",
    firstName: "",
    surname: "",
    birthDate: null,
    permissionLevel: ""
  },
  seizures: [],
  contacts: [],
  // seizureTypes: [],
  // contactTypes: []
};

const demoState = {
  profile: {
    accessToken: "demoAccessToken",
    userId: "demoUserId",
    email: "demo_profil@exempel.com",
    firstName: "Demo",
    surname: "Profil",
    birthDate: null,
    permissionLevel: "demo"
  },
  seizures: [
    {
      _id: _seizureId1,
      date: yesterdayAt22,
      duration: 90,
      type: "Fokalt utan medvetandepåverkan",
      trigger: "",
      comment: "Fick synrubbningar när jag borstade tänderna."
    },
    {
      _id: _seizureId2,
      date: dayBeforeAt19,
      duration: 90,
      type: "Fokalt till generaliserat",
      trigger: "medicin",
      comment: "Krampade i soffan, hade glömt att ta min medicin på morgonen."
    },
    {
      _id: _seizureId3,
      date: lastWeekAt14,
      duration: 30,
      type: "Fokalt med medvetandepåverkan",
      trigger: "trötthet",
      comment: "Försvann en stund när jag jobbade framför datorn, kände mig trött efter en dålig natt."
    },
  ],
  contacts: [
    {
      _id: _contactId1,
      type: "Vårdkontakt",
      firstName: "Maria",
      surname: "Svensson",
      phoneNumber: "0801234567",
      category: "Vårdcentral",
    },
    {
      _id: _contactId2,
      type: "Nödkontakt",
      firstName: "Demokontakt",
      surname: "Profil",
      phoneNumber: "0701234567",
      category: "Syskon",
    },
  ],
};

export const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    activateDemoMode: (state) => {
      Object.assign(state, demoState);
    },
    setAccessToken: (state, action) => {
      const { accessToken } = action.payload;
      state.profile.accessToken = accessToken;
    },
    setUserId: (state, action) => {
      const { userId } = action.payload;
      state.profile.userId = userId;
    },
    setEmail: (state, action) => {
      const { email } = action.payload;
      state.profile.email = email;
    },
    setFirstName: (state, action) => {
      const { firstName } = action.payload;
      state.profile.firstName = firstName;
    },
    setSurname: (state, action) => {
      const { surname } = action.payload;
      state.profile.surname = surname;
    },
    setBirthDate: (state, action) => {
      const { birthDate } = action.payload;
      state.profile.birthDate = birthDate;
    },
    setPermissionLevel: (state, action) => {
      const { permissionLevel } = action.payload;
      state.profile.permissionLevel = permissionLevel;
    },
    updateLocalProfile: (state, action) => {
      const { firstName, surname, birthDate } = action.payload;
      state.profile.firstName = firstName;
      state.profile.surname = surname;
      state.profile.birthDate = birthDate;
    },
    setSeizures: (state, action) => {
      const { seizures } = action.payload;
      state.seizures = seizures;
    },
    setContacts: (state, action) => {
      const { contacts } = action.payload;
      state.contacts = contacts;
    },
    // setSeizureTypes: (state, action) => {
    //   const { seizureTypes } = action.payload;
    //   state.seizureTypes = seizureTypes;
    // },
    // setContactTypes: (state, action) => {
    //   const { contactTypes } = action.payload;
    //   state.contactTypes = contactTypes;
    // },
    addSeizure: (state, action) => {
      const newSeizure = action.payload;
      const newSeizuresList = [newSeizure, ...state.seizures];
      state.seizures = newSeizuresList;
    },
    addContact: (state, action) => {
      const newContact = action.payload;
      const newContactsList = [newContact, ...state.contacts];
      state.contacts = newContactsList;
    },
    // addSeizureType: (state, action) => {
    //   const newSeizureType = action.payload;
    //   const newSeizureTypesList = [newSeizureType, ...state.seizureTypes];
    //   state.seizureTypes = newSeizureTypesList;
    // },
    updateSeizure: (state, action) => {
      const updatedSeizuresList = state.seizures.map((item) => {
        if (item._id !== action.payload._id) {
          return item
        } else {
          return {
            ...item,
            ...action.payload
          }
        }
      });
      state.seizures = updatedSeizuresList;
    },
    updateContact: (state, action) => {
      const updatedContactsList = state.contacts.map((item) => {
        if (item._id !== action.payload._id) {
          return item
        } else {
          return {
            ...item,
            ...action.payload
          }
        }
      });
      state.contacts = updatedContactsList;
    },
    // updateSeizureType: (state, action) => {
    //   const updatedSeizureTypesList = state.seizureTypes.map((item) => {
    //     if (item._id !== action.payload._id) {
    //       return item
    //     } else {
    //       return {
    //         ...item,
    //         ...action.payload
    //       }
    //     }
    //   });
    //   state.seizureTypes = updatedSeizureTypesList;
    // },
    deleteSeizure: (state, action) => {
      const remainingSeizures = state.seizures.filter((item) => item._id !== action.payload._id);
      state.seizures = remainingSeizures;
    },
    deleteContact: (state, action) => {
      const remainingContacts = state.contacts.filter((item) => item._id !== action.payload._id);
      state.contacts = remainingContacts;
    },
    // deleteSeizureType: (state, action) => {
    //   const remainingSeizureTypes = state.seizureTypes.filter((item) => item._id !== action.payload._id);
    //   state.seizureTypes = remainingSeizureTypes;
    // },
    restart: () => {
      return initialState
    }
  }
});